@use "_mantine" as mantine;
@use '../../styles/variables';

.installBanner {
  position: fixed;
  z-index: 1000;

  width: 400px;
  bottom: 16px;
  left: 16px;

  border: solid var(--mantine-color-gray-5) 1px;
  opacity: 0.8;

  // Phone
  @media (max-width: variables.$phone-max-width) {
    width: 100vw;
    bottom: 0;
    left: 0;
  }

  button {
    margin-left: 8px;
  }
}
