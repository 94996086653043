@use "_mantine" as mantine;
.forgotPassword {
  margin-left: 3px;

  a {
    font-size: 0.75rem;
  }
}

.message {
  font-size: 1rem;
  margin-bottom: 24px;
}
