@use "_mantine" as mantine;
@use "../../styles/variables";

.header {
  height: variables.$header-height; //rem(56px);
  // margin-bottom: 120px; // rem(120px);
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  padding-left: 16px;
  padding-right: 16px;
}

.inner {
  height: variables.$header-height; // rem(56px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px; // rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.linkLabel {
  margin-right: 5px; // rem(5px);
}

.brand {
  a {
    color: var(--mantine-primary-color-filled);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;

    &:hover {
      color: var(--mantine-primary-color-filled-hover);
      text-decoration: underline;
    }
  }
}

.menuItem {
  &.menuItemActive {
    background-color: var(--mantine-color-gray-2);
  }
}
